import React from "react";
import SystemPropertiesLink from "./SystemPropertiesLink";

function SystemProperties() {
  return (
    <div id="sp-container">
      <div id="sp-pc-wrapper">
        <div id="sp-pc"></div>
      </div>
      <div id="sp-text-container">
        <div className="sp-section-container">
          <div id="sp-section-header">Tech:</div>
          <SystemPropertiesLink
            label="React"
            url="https://reactjs.org"
          ></SystemPropertiesLink>
          <SystemPropertiesLink
            label="TypeScript"
            url="https://www.typescriptlang.org/"
          ></SystemPropertiesLink>
        </div>
        <div className="sp-section-container">
          <div className="sp-section-header">Libraries:</div>
          <div className="sp-list-wrapper">
            <SystemPropertiesLink
              label="react-resizable"
              url="https://github.com/STRML/react-resizable"
            ></SystemPropertiesLink>
            <SystemPropertiesLink
              label="react-draggable"
              url="https://github.com/STRML/react-draggable"
            ></SystemPropertiesLink>
            <SystemPropertiesLink
              label="react-onclickoutside"
              url="https://github.com/Pomax/react-onclickoutside"
            ></SystemPropertiesLink>
            <SystemPropertiesLink
              label="Snowplow Micro"
              url="https://github.com/snowplow-incubator/snowplow-micro/"
            ></SystemPropertiesLink>
          </div>
        </div>
        <div className="sp-section-container">
          <div className="sp-section-header">Tools:</div>
          <div className="sp-list-wrapper">
            <SystemPropertiesLink
              label="Arch Linux"
              url="https://www.archlinux.org/"
            ></SystemPropertiesLink>
            <SystemPropertiesLink
              label="VSCodium"
              url="https://github.com/VSCodium/vscodium"
            ></SystemPropertiesLink>
            <SystemPropertiesLink
              label="rxvt-unicode"
              url="http://software.schmorp.de/pkg/rxvt-unicode.html"
            ></SystemPropertiesLink>
          </div>
        </div>
        <div className="sp-section-container">
          <div className="sp-section-header">Hosting:</div>
          <div className="sp-list-wrapper">
            <SystemPropertiesLink
              label="GitLab Pages"
              url="https://docs.gitlab.com/ee/user/project/pages/"
            ></SystemPropertiesLink>
          </div>
        </div>
        <div className="sp-section-container">
          <div className="sp-section-header">Registered To:</div>
          <div className="sp-list-wrapper">
            <div className="sp-list-item-wrapper">
              <div className="sp-list-element-icon-wrapper">
                <div className="sp-github-icon sp-icon"></div>
              </div>
              <div className="sp-list-label-wrapper">
                <div className="sp-list-label">Greg Leonard</div>
              </div>
            </div>
            <div className="sp-list-wrapper">
              <div className="sp-list-item-wrapper">
                <div className="sp-list-element-icon-wrapper">
                  <div className="sp-github-icon sp-icon"></div>
                </div>
                <div className="sp-list-label-wrapper">
                  <a
                    className="sp-list-label"
                    href="mailto:gregjamesleonard@gmail.com"
                  >
                    gregjamesleonard@gmail.com
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SystemProperties;
