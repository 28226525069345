import React from "react";
import { trackStructEvent } from "@snowplow/browser-tracker";

interface IProps {
  label: string;
  url: string;
}

interface IState {}

class SystemPropertiesLink extends React.Component<IProps, IState> {
  render() {
    return (
      <div
        className="sp-list-item-wrapper"
        onClick={() => {
          trackStructEvent({
            category: "Link",
            action: "Visit",
            label: this.props.url,
            property: "",
            value: 0.0,
          });
        }}
      >
        <div className="sp-list-label-wrapper">
          <a className="sp-list-label" href={this.props.url}>
            {this.props.label}
          </a>
        </div>
      </div>
    );
  }
}

export default SystemPropertiesLink;
