import React from "react";
import { WindowStateEnum } from "../../../constants/index";
import { trackStructEvent } from "@snowplow/browser-tracker";

interface IProps {
  setWindowState: Function;
  windowStateName: string;
  taskbarStateName: string;
}

interface IState {}

class Minimise extends React.Component<IProps, IState> {
  render() {
    return (
      <div
        onClick={() => {
          trackStructEvent({
            category: "Window",
            action: "Minimise",
            label: this.props.windowStateName.replace("WindowState", ""),
            property: "",
            value: 0.0,
          });
          this.props.setWindowState(
            this.props.taskbarStateName,
            this.props.windowStateName,
            WindowStateEnum.MINIMISED
          );
        }}
        className="window-control-wrapper window-min-wrapper"
      >
        <div className="window-control window-min"></div>
      </div>
    );
  }
}

export default Minimise;
